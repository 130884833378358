import React, { useEffect, useState } from "react";
import Layout from "../components/Templates/Layout";
import app from "gatsby-plugin-firebase-v9.0";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Addy = ({ postID }) => {
  const [dataAll, setDataAll] = useState("");

  const [firebaseUID, setFirebaseUID] = useState(null);

  const [noData, setNoData] = useState(false);

  //  const people = parse(dataAll);

  const dropdownLanguage = {
    none: "Please select",
    yes: "I'll be there!",
    maybe: "I'm not sure",
    no: "Sorry, I can't make it",
  };

  const dropdownLanguageChild = {
    none: "Please select",
    yes: "I'll be there!",
    maybe: "I'm not sure",
    no: "I'll be left at home",
  };

  useEffect(() => {
    const database = getDatabase(app);
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        // Get all data
        setFirebaseUID(auth.currentUser.uid);
        const fbQuery = ref(database, "/people/");
        onValue(fbQuery, (snapshot) => {
          const data = snapshot;
          var returnArr = [];
          snapshot.forEach(function (childSnapshot) {
            var item = childSnapshot.val();
            item.key = childSnapshot.key;

            returnArr.push(item);
          });
          setDataAll(returnArr);
          if (!data) {
            setNoData(true);
          }
        });
      })
      .catch((error) => {
        alert("There was an error getting the data");
      });
  }, [postID]);

  if (noData)
    return (
      <section className="py-5 brand-background-black">
        <div className="container text-center brand-text-white">
          <div className="row">
            <p>Sorry, we can't find any data</p>
          </div>
        </div>
      </section>
    );

  if (!dataAll)
    return (
      <section className="py-5 brand-background-black brand-section-min-height">
        <div className="container text-center py-5 brand-text-white">
          <FontAwesomeIcon icon="spinner" size="3x" spin pulse />
        </div>
      </section>
    );

  return (
    <Layout>
      <section>
        <div className="container">
          {dataAll &&
            dataAll.map((item, index) => {
              return (
                <div
                  className={`row mb-4 ${
                    item.rsvp && "brand-background-pink brand-text-white"
                  }`}
                >
                  <div className="col-12 p-4 border">
                    {item.rsvp && (
                      <p className="p-2">
                        <strong>RSVP IS IN BABY - {item.rsvp}</strong>
                      </p>
                    )}
                    <h3>{item.key}</h3>

                    {item.family ? (
                      <p className="bg-success text-white p-2">
                        <strong>FAMILY ACCOUNT</strong>
                      </p>
                    ) : (
                      <p className="bg-secondary text-white p-2">FRIEND</p>
                    )}

                    <p>https://www.cp4rp.com/?a={item.key}</p>

                    {!item.rsvp && (
                      <div className="border p-2">
                        <p>
                          💌 <strong>You've got wedding mail!</strong>💌
                        </p>

                        <p>It's not long until our big day!</p>

                        <p>
                          We hope you can join us to celebrate our marriage(!)
                          on 20th May 2023
                        </p>

                        <p>
                          <strong>Please confirm your RSVP</strong>
                        </p>

                        <p>Let us know your dietary requirements</p>

                        <p>
                          <strong>
                            Bringing the kids? Let us know what they're eating!
                          </strong>
                        </p>

                        <p>
                          You can also find all of the details on our wedding
                          website, built with love by Richy:
                        </p>

                        <ul>
                          <li>Woodies Warm Up (Friday night)</li>
                          <li>Details for the Big Day</li>
                          {item.family && (
                            <li>Summerhill Sunday (Family only)</li>
                          )}
                          <li>The Line-Up</li>
                          <li>Gifts</li>
                          <li>Hotels</li>
                        </ul>
                        <p>...and more!</p>

                        <p>
                          Here's your link: https://www.cp4rp.com/?a={item.key}
                        </p>

                        <p>
                          We hope you can still make it and can't wait to see
                          you there ❤️
                        </p>

                        <p>Claire & Richy x</p>
                      </div>
                    )}

                    {item.details.uid ? (
                      <p className="text-success">VIEWED</p>
                    ) : (
                      <p>NOT VIEWED</p>
                    )}

                    <div>
                      {item.details.family && (
                        <span className="me-3">
                          {item.details.family ? "FAMILY" : "FRIEND"}
                        </span>
                      )}
                      {item.details.email && (
                        <span className="me-3">{item.details.email}</span>
                      )}
                      {item.details.phone && <span>{item.details.phone}</span>}
                      <hr />
                    </div>

                    {item.details.note && <p>{item.details.note}</p>}

                    <ol>
                      {item.guests.map((guest, index) => {
                        return (
                          <li className="border p-2">
                            {guest.status === "yes" ? (
                              <span className="text-success">YES</span>
                            ) : guest.status === "maybe" ? (
                              <span className="text-secondary">MAYBE</span>
                            ) : guest.status === "no" ? (
                              <span className="text-danger">NO</span>
                            ) : (
                              <span className="text-secondary">-</span>
                            )}
                            <span className="ms-3">
                              {guest.name} ({guest.type})
                            </span>
                            {guest.diet && (
                              <p>
                                Diet:{" "}
                                {guest.diet ? (
                                  <strong>{guest.diet}</strong>
                                ) : (
                                  <span>Nothing</span>
                                )}
                              </p>
                            )}
                            {guest.type === "Child" && (
                              <>
                                {guest.status === "yes" && (
                                  <>
                                    {guest.food === "kids" ? (
                                      <p>Kids meal</p>
                                    ) : guest.food === "adult" ? (
                                      <p>Adult meal</p>
                                    ) : guest.food === "none" ? (
                                      <p>No meal</p>
                                    ) : (
                                      <p>Kidz meal</p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </Layout>
  );
};

export default Addy;

/*
{dataAll &&
            dataAll.people.map((item, index) => {
              return (
                <li>
                  <div className="row">{JSON.stringify(item)}</div>
                </li>
              );
            })}
*/
